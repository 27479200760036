export default {
	cinema: {
		address: 'пл. Советская, 9',
		place: null,
		phoneNumbers: ['+7 (920) 117-12-12'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/club116980067',
		twitter: null,
		odnoklassniki: 'https://ok.ru/profile/558360137465',
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCwuuY9F',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
